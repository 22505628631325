.qrcode {
  border: solid;
  border-radius: 30px;
  background-color: black;
  padding: 20px;
}

.chatbox {
  border: 2px solid #dedede;
  border-radius: 30px;
  padding: 100px 100px 100px 100px;
  margin-top: 125px;
  margin-bottom: 100px;
}

.textArea {
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}

.login {
  border: 2px solid #dedede;
  border-radius: 30px;
  padding: 55px 50px 100px 50px;
  box-shadow: 10px;
  /* margin-top: 125px; */
  /* margin-bottom: 100px; */
}